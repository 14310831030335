<template>
  <div class="edu">
    <div class="titler-header-box">
      <div class="cont-title">教育经历 </div>

      <div class="info-message-right">
        <a @click="created" class="add-content">
          <i class="el-icon-edit icon"></i>
          <div style="margin-left:10px">新建</div>
        </a>
      </div>

    </div>
    <!-- 求职意向的Modal框 -->
    <el-dialog title="教育经历" :visible.sync="visibility"  :close-on-click-modal="false">
      <FormGenerator v-if="visibility" ref="forms" :defaultValue="eduData" :formOption="educationModalOptions"
        @close="changMotal" @submit="submit" />
    </el-dialog>
    <!-- ****** -->
    <div v-for="item, index in data" :key="index" class="item-box">
      <div class="item-content-wraper">
        <div class="school-name">{{ item.school_name }} <span class="tag">{{ item.degree }}</span></div>
        <div style="display:felx;" class="operation-btn-box">
          <span @click="deleteEduData(index)" class="add-content delete">
            <i class="el-icon-circle-close icon"></i>
            <div style="margin-left:10px">删除</div>
          </span>

          <a @click="processEduData(index)" class="add-content ">
            <i class="el-icon-edit icon"></i>
            <div style="margin-left:10px">编辑</div>
          </a>
        </div>
      </div>

      <div class="edu-info">
        <div class="edu-name"><span class="subspan">专业：</span>{{ item.major }}</div>
        <div class="edu-time"><span
            class="subspan">在校时间：</span>{{ dealWithTime(item.start_date) + '-' + dealWithTime(item.end_date) }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import FormGenerator from 'components/formGenerator/formGenerator'
import { resumeData } from "constants/publicData"
export default {
  name: "Education",
  props: ["data"],
  data: () => ({
    visibility: false,
    eduData: {},
    curIndex: null,
    educationModalOptions: {
      labelPosition: 'top',
      formClass: 'lable-position-top',
      formName: 'intentionFrom',
      items: [
        {
          type: 'input',
          label: '学校',
          key: 'school_name',
          placeholder: '请输入学校',
          rules: [{ required: true, message: "请输入学校" }]
        },
        {
          type: 'select',
          label: '学历',
          key: 'degree',
          placeholder: '请选择学历',
          options: resumeData.degrees,
          rules: [{ required: true, message: "请选择学历" }]
        },
        {
          type: 'input',
          label: '专业',
          key: 'major',
          placeholder: '请输入专业',
          rules: [{ required: true, message: "请输入专业" }]
        },
        {
          type: 'select-monthrange',
          label: '在校时间',
          key: 'doubleTime',
          startPlaceholder: '请选择入学时间',
          endPlaceholder: '请选择毕业时间',
          rules: [{ required: true, message: "请选择入学时间以及毕业时间" }]
        },
      ]
    }
  }),
  methods: {
    created() {
      this.curIndex = null
      this.eduData = {}
      this.changMotal()
    },
    dealWithTime(time, key) {
      return this.$dayjs(time).format(key ? key : "YYYY/MM") || null
    },
    changMotal() {
      this.visibility = !this.visibility
    },
    processEduData(index) {
      this.curIndex = index
      let data = { ...this.data[index] }
      data.doubleTime = [new Date(data.start_date), new Date(data.end_date)]
      this.eduData = data
      this.changMotal()
    },
    deleteEduData(index) {
      let eduData = [...this.data]
      eduData.splice(index, 1)
      this.$emit("formSubmit", 'educations', { educations: eduData })
    },
    submit(valid, value) {
      console.log(valid, value);
      let eduData = [...this.data]
      value.start_date = value.doubleTime[0]
      value.end_date = value.doubleTime[1]
      delete value.doubleTime
      if (this.curIndex !== null) {
        eduData.splice(this.curIndex, 1, value)
      } else {
        eduData.push(value)
      }
      this.$emit("formSubmit", valid, { educations: eduData })
    }

  },
  computed: {

  },
  components: {
    FormGenerator
  },
  watch: {},

}
</script>
