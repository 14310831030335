<template>
  <el-form 
    v-if="formOption"
    :model="values" 
    :label-width="formOption.labelWidth||'100px'" 
    :ref="formOption.formName||'childForm'" 
    :label-position="formOption.labelPosition||'left'" 
    class="form-generator"
    :class="formOption.formClass||''"
    >

    <FormGeneratorContainer
      v-for="(item,index) in formOption.items" 
      :key="item.key" 
      :itemIndex="index"
      v-model="values[item.key]"
      :item="item"
    />


    <el-form-item v-if="!formOption.noButton">
      <el-button type="primary" @click="onSubmit">保存</el-button>
      <el-button @click="close">取消</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
// import FormGeneratorItem from 'components/formGenerator/formGeneratorItem'
import FormGeneratorContainer from 'components/formGenerator/formGaneratorContainer'
export default {
  name:"formGenerator",
  props:["formOption","defaultValue","formValues"],
  model:{
    prop:'formValues',
    event:'formChange'
  },
  data:()=>({
    values:{}
  }),
  methods:{
    close(){
      this.$emit('close')
    },
    getFromValues(){
      let values = {...this.values}
      this.$emit('getFromValues',values)
      return values
    },
    onSubmit() {
      let values = {...this.values}
      console.log('formValues',values);

      if(Object?.keys(this.fromRules)?.length){
        this.$refs[this.formOption.formName||'childForm'].validate((valid) => {
          if (valid) {
            console.log('yes',valid);
            this.$emit('submit',valid,values)
          } else {
            console.log('no',valid);
            this.$emit('submit',valid,values)
            console.err('This form is invalid');
          }
        });
      }else{
        console.log('no valid',values);
        this.$emit('submit',null,values)
      }
    },
    valuesChange(key,value){
      let values = {...this.values}
      values[key] = value;
      this.values = values

    },
  },
  computed:{
    fromRules(){
      if(this.formOption?.items?.length){
        let fromRules = {}
        this.formOption.items.forEach(item=>{
          if(item.children){
            fromRules[item.key] = item.children.map(o=>{
              return o.rules
            })
          }else{
            fromRules[item.key] = item.rules
          }
        })
        return fromRules
      }else{
        return {}
      }
    }
  },
  components:{
    FormGeneratorContainer
  },
  watch:{
    defaultValue:{
      handler(val){
        if(val){
          this.values = {...val}
        }
      },
      immediate: true 
    },
    values(val){
      this.$emit('formChange',val)
    }
  },
}
</script>
<style lang="scss">
// @import "constants/style/public";
  .form-generator{
    text-align: left;
    
  }
  .el-form--label-top{
    .el-form-item__label{
        height: 38px;
    }
  }
  
  
</style>