<template>
  <div class="record-wrapper">
    <div v-if="list" class="jobs-rapper">
      <div class="jobs-item-box" v-for="l in list" :key="l.job_id">
        <span class="jobs-set-time">投递时间：{{dealWithTime(l.created_at,"YYYY/MM/DD HH:mm")}}</span>
        <JobCard :data="l" :link="toJobDetail+ l.job_id"/>
      </div>
      <div class="pagination" v-if="nums">
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page="page"
          :total="total"
          :page-size="nums"
          :hide-on-single-page=" !total || total < nums"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <el-empty v-else description="暂无投递记录"></el-empty>
  </div>
</template>
<script>
import {mapState} from "vuex"
import JobCard from "components/jobCard";
import {toJobDetail} from "constants/routerLink"
export default {
  name:"",
  data:()=>({
    list:null,
    page: 1,
    total: null,
    nums:10
  }),
  created() {
    this.getData()
  },
  methods:{
    handleCurrentChange(page) {
      this.page = page;
      this.getData();
    },
    dealWithTime(time,key){
      return this.$dayjs(time).format(key?key:"YYYY/MM")||null
    },
    getData(){
      this.$axiosRequest({
        name: "getUserDeliverRecord",
        ids: [this.userId],
        token:true
      })
        .then((res) => {
          if (res.status === 200||res.status === 201) {
            console.log('td',res);
            this.list = res.data.applied_job_list
            this.total = res.data.applied_job_list.length
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  computed:{
    ...mapState({
      userId:(state) => state.user_id,
    }),
    toJobDetail:()=>toJobDetail,
  },
  components:{
    JobCard
  },
  watch:{},

}
</script>
<style lang="scss">
@import "constants/style/public";
.record-wrapper{
  color: #999;
  .jobs-set-time{
    display: block;
    font-size: 12px;
    margin-bottom: 6px;
  }
  .jobs-item-box{
    padding: 15px 0 0;
  }
  .pagination{
    text-align: center;
  }
  .jobs-rapper{
    .job-card{
      border: 0;
      width:100%;
      height: 95px;
      box-shadow: 0 0 0 transparent;
      padding:0;
      border-bottom:1px solid #efefef;
    }
  }
 
}
</style>