<template>
  <div class="job-intention " v-if="data">
    <div class="titler-header-box">
      <div class="cont-title">求职意向</div>
      <div class="info-message-right">
        <a @click="changMotal" class="add-content">
          <i class="el-icon-edit icon"></i>
          <div style="margin-left: 10px">编辑</div>
        </a>
      </div>
    </div>

    <div class="job-intention-content">
      <div class="job-intention-content-name">
        <div class="name">
          {{ data.expect_positions }}
        </div>
        <div class="selary">
          {{ data.expect_salary }}元/月
        </div>
      </div>
      <div class="job-intention-content-bottom">
        <div class="adress">
          <span class="subspan">期望地点：</span>
          {{ data.expect_areas }}
        </div>
        <div class="industry">
          <span class="subspan">期望行业：</span>
          {{ data.expect_industry }}
        </div>
      </div>
    </div>
    <!-- 求职意向的Modal框 -->
    <el-dialog title="求职意向" :visible.sync="visibility"  :close-on-click-modal="false">
      <FormGenerator ref="forms" v-if="visibility" :defaultValue="processIntentionData(data)"
        :formOption="intentionModalOptions" @close="changMotal" @submit="submit" />
    </el-dialog>
  </div>
</template>

<script>
import FormGenerator from 'components/formGenerator/formGenerator'
import { selectOriginNode } from "constants/utils";
import { resumeData, category, /*city,*/wxCity } from "constants/publicData";
export default {
  name: "intention",
  props: ["data"],
  data: () => ({
    visibility: false,
    curIndex: null,
    intentionModalOptions: {
      labelPosition: "top",
      formClass: "lable-position-top",
      formName: "intentionFrom",
      items: [
        {
          type: "job",
          label: "期望行业和职位",
          key: "expect_industry",
          placeholder: "选择期望行业和职位",
          options: category,
          rules: [{ required: true, message: "选择期望行业和职位" }],
        },
        {
          type: "search-select",
          label: "期望地点",
          key: "expect_areas",
          placeholder: "请选择期望地点",
          options: wxCity,
          rules: [{ required: true, message: "请选择期望地点" }],
        },
        {
          type: "inputNumber",
          label: "期望薪资（月）",
          key: "expect_salary",
          placeholder: "输入期望薪资",
          rules: [{ required: true, message: "输入期望薪资" }],
        },
      ],
    },
  }),
  methods: {
    created() {
      this.curIndex = null
      this.eduData = {}
      this.changMotal()
    },
    processIntentionData(data) {
      let newData = { ...data };
      newData.expect_industry = selectOriginNode(
        data.expect_positions,
        category
      );
      return newData;
    },
    dealWithTime(time, key) {
      return this.$dayjs(time).format(key ? key : "YYYY/MM") || null
    },
    changMotal() {
      this.visibility = !this.visibility
    },
    submit(valid, value) {
      this.$emit("formSubmit", valid, value)
    }

  },
  computed: {
    category: () => category,
    resumeOptionsData: () => resumeData,

  },
  components: {
    FormGenerator
  },
  watch: {},

}
</script>