<template>
    <el-form-item :label="noDefaultLabel?'':item.label" :prop="itemKey||item.key" :rules="item.rules">
      <!-- 文本框 -->
      <el-input v-if="item.type==='input'" v-model="state"  type="text" :value="state"  :placeholder="item.placeholder" ></el-input>
      <!-- 邮箱 -->
      <el-input v-if="item.type==='email'" v-model="state" type="text" prefix-icon="el-icon-paperclip" :value="state" :placeholder="item.placeholder" ></el-input>
      
      <!-- 身份证 -->
      <el-input v-if="item.type==='idCard'" v-model="state" prefix-icon="el-icon-postcard" type="text" :value="state" :placeholder="item.placeholder" ></el-input>
      
      <!-- 数字输入框 -->
      <el-input v-if="item.type==='inputNumber'"  :min="item.min"  v-model="state" type="number" :value="state" :placeholder="item.placeholder"  ></el-input>
      
      <!-- 手机号 -->
      <el-input v-if="item.type==='phone'" v-model="state" type="number" prefix-icon="el-icon-mobile-phone" :value="state" :placeholder="item.placeholder"  ></el-input>
    
      <!-- 下拉选择框 -->
      <el-select v-if="item.type==='select'" v-model="state" :value="state" :placeholder="item.placeholder"  style="width:100%">
        <el-option v-for="l in item.options" :key="l.value||l.label||l" :value="l.value||l" :label="l.label||l"></el-option >
      </el-select>

      <!-- 可搜索的下拉选择框 -->
      <el-select v-if="item.type==='search-select'" v-model="state" filterable :value="state" :placeholder="item.placeholder"  style="width:100%">
        <el-option v-for="l in item.options" :key="l.value||l.label||l" :value="l.value||l" :label="l.label||l"></el-option >
      </el-select>

      <!--  -->
      <!-- <el-slider v-if="item.type==='slider'" v-model="value1"></el-slider> -->

      <!-- 级联选择器 -->
      <el-cascader v-if="item.type==='city'" v-model="state" :value="state" :options="item.options"  style="width:100%"/>
      <el-cascader v-if="item.type==='job'" v-model="state" :value="state" :options="item.options"  style="width:100%"/>

      <!-- 开关 -->
      <el-switch v-if="item.type==='switch'" v-model="state" :value="item.value" ></el-switch>

      <!-- 多选框 -->
      <el-checkbox-group v-if="item.type==='checkbox'" v-model="state" :value="state" >
        <el-checkbox v-for="l in item.options" :key="l.text" :label="item.key" :name="item.key"></el-checkbox>
      </el-checkbox-group>

      <!-- 单选框 -->
      <el-radio-group  v-if="item.type==='radio'" v-model="state" :value="state"  :disabled="item.disabled">
        <el-radio v-for="l in item.options" :key="l.text||l" :label="l.value||l.text||l" :border="item.border" :disabled="l.disabled" >{{l.text||l}}</el-radio>
      </el-radio-group>

      <!-- 单选框 -->
      <el-radio-group  v-if="item.type==='radio-button'" v-model="state"  :disabled="item.disabled" :size="item.size">
        <el-radio-button v-for="l in item.options" :key="l.text" :label="l.value||l.text" :border="item.border" :disabled="l.disabled||item.disabled" >{{l.text}}</el-radio-button>
      </el-radio-group>

      <!-- 文本域 -->
      <el-input v-if="item.type==='textarea'" v-model="state" type="textarea" :placeholder="item.placeholder" :autosize="item.autosize" :rows="item.rows" :value="state" :show-word-limit="item.maxLength?true:false" :maxlength="item.maxLength" ></el-input>
      
      <!-- 时间选择器 -->
      <el-date-picker v-if="item.type==='select-date'" type="date" v-model="state" :placeholder="item.placeholder" style="width: 100%;" ></el-date-picker>
      <el-date-picker v-if="item.type==='select-week'" type="week" v-model="state" format="yyyy 第 WW 周" :placeholder="item.placeholder||'选择周'"  style="width: 100%;" ></el-date-picker>
      <el-date-picker v-if="item.type==='select-month'" type="month" v-model="state" :placeholder="item.placeholder||'选择月份'"  style="width: 100%;" ></el-date-picker>
      <el-date-picker v-if="item.type==='select-year'" type="year" v-model="state" :value-format="item.format" :placeholder="item.placeholder||'选择年份'" style="width: 100%;" ></el-date-picker>
      <el-date-picker v-if="item.type==='select-dates'" type="dates" v-model="state" :placeholder="item.placeholder||'选择一个或者多个日期'" style="width: 100%;" ></el-date-picker>
      
      <!-- 时间组合选择器 -->
      <el-date-picker v-if="item.type==='select-daterange'" type="daterange" v-model="state"  unlink-panels range-separator="至" :start-placeholder="item.startPlaceholder||'开始日期'" :end-placeholder="item.endPlaceholder||'结束日期'"  style="width: 100%;" ></el-date-picker>
      <el-date-picker v-if="item.type==='select-monthrange'" type="monthrange" v-model="state" unlink-panels range-separator="至"  :start-placeholder="item.startPlaceholder||'开始年月'" :end-placeholder="item.endPlaceholder||'结束年月'"  style="width: 100%;" ></el-date-picker>

    </el-form-item>
</template>
<script>
export default {
  name:"formGeneratorItem",
  props:["item",'value','noDefaultLabel','itemKey'],
  model:{
    prop:"value",
    event:"change"
  },
  data:()=>({
    state:null,
  }),
  methods:{},
  computed:{},
  components:{},
  watch:{ 
    state(val,prev){
      if(val!==prev){
        this.$emit('change',val)
      }
      
    },
    value:{
      handler(val){
        if(val){
          this.state = val
          // switch(this.item.type){
          //   case 'select-monthrange': 
          //     this.$nextTick(()=>{this.state = [new Date(val[0]),new Date(val[1])]}); break;
          //   case 'select-daterange' : 
          //     this.$nextTick(()=>{this.state = [new Date(val[0]),new Date(val[1])]}); break;
          //   default: 
          // }
        }
      },
      immediate:true
    }
  }
}
</script>
<style lang="scss">
// @import "constants/style/public";
  .el-form-item{
    width: 100%;
  }
</style>