<template>
  <div class="works">
    <div class="titler-header-box">
      <div class="cont-title">工作经历 </div>
      <div class="info-message-right">
        <a @click="created" class="add-content">
          <i class="el-icon-edit icon"></i>
          <div style="margin-left:10px">新建</div>
        </a>
      </div>
    </div>
    <!-- 工作经历的Modal框 -->
    <el-dialog title="填写工作经历" :visible.sync="visibility" :close-on-click-modal="false">
      <FormGenerator v-if="visibility" ref="forms" :defaultValue="workExperiencesData"
        :formOption="workExperiencesModalOptions" @close="changMotal" @submit="submit" />
    </el-dialog>
    <!-- ****** -->
    <div v-for="item, index in data" :key="index" class="item-box">
      <div class="item-content-wraper">
        <div class="company-name">{{ item.company_name }} <span class="tag">{{ workTypeOptions[item.work_type] }}</span>
        </div>
        <div style="display:felx;" class="operation-btn-box">
          <span @click="deleteData(index)" class="add-content delete">
            <i class="el-icon-circle-close icon"></i>
            <div style="margin-left:10px">删除</div>
          </span>
          <a @click="processData(index)" class="add-content ">
            <i class="el-icon-edit icon"></i>
            <div style="margin-left:10px">编辑</div>
          </a>
        </div>
      </div>

      <div class="work-info">
        <div class="work-name"><span class="subspan">职位名称：</span>{{ item.job_title }}</div>
        <div class="work-time"><span class="subspan">在职时间：</span>{{ dealWithTime(item.start_date) + '-' +
            dealWithTime(item.end_date)
        }}</div>
      </div>
      <div class="work-content">
        <div class="subspan">工作内容:</div>
        <div class="summary">{{ item.summary }}</div>
      </div>
    </div>
  </div>

</template>
<script>
import FormGenerator from 'components/formGenerator/formGenerator'
import { resumeData } from "constants/publicData"
export default {
  name: "workExperiences",
  props: ["data"],
  data: () => ({
    visibility: false,
    workExperiencesData: {},
    curIndex: null,
    workExperiencesModalOptions: {
      labelPosition: 'top',
      formClass: 'lable-position-top',
      formName: 'intentionFrom',
      items: [
        {
          type: 'input',
          label: '公司名称',
          key: 'company_name',
          placeholder: '请输入公司名称',
          rules: [{ required: true, message: "请输入公司名称" }]
        },
        {
          type: 'select',
          label: '工作类型',
          key: 'work_type',
          placeholder: '请选择工作类型',
          options: resumeData.workType,
          rules: [{ required: true, message: "请选择工作类型" }]
        },
        {
          type: 'input',
          label: '职位名称',
          key: 'job_title',
          placeholder: '请输入职位名称',
          rules: [{ required: true, message: "请输入职位名称" }]
        },
        {
          type: 'select-monthrange',
          label: '在职时间',
          key: 'doubleTime',
          startPlaceholder: '请选择入职时间',
          endPlaceholder: '请选择离职时间',
          rules: [{ required: true, message: "请选择入职时间以及离职时间" }]
        },
        {
          type: 'textarea',
          label: '工作内容',
          key: 'summary',
          placeholder: '请输入工作内容',
          rules: [{ required: true, message: "请输入工作内容" }]
        },
      ]
    }
  }),
  methods: {
    created() {
      this.curIndex = null
      this.workExperiencesData = {}
      this.changMotal()
    },
    dealWithTime(time, key) {
      return this.$dayjs(time).format(key ? key : "YYYY/MM") || null
    },
    changMotal() {
      this.visibility = !this.visibility
    },
    processData(index) {
      this.curIndex = index
      let data = { ...this.data[index] }
      data.doubleTime = [new Date(data.start_date), new Date(data.end_date)]
      this.workExperiencesData = data
      this.changMotal()
    },

    deleteData(index) {
      let data = [...this.data]
      data.splice(index, 1)
      this.$emit("formSubmit", null, { work_experiences: data })
    },
    submit(valid, values) {
      console.log('modeSubmit', valid, values);

      let data = [...this.data]//展开原始的工作经历的数组并保存

      values.start_date = values.doubleTime[0] //设置开始时间
      values.end_date = values.doubleTime[1]  //设置结束时间

      delete values.doubleTime //删除无用的doubleTime

      //将新修改或者创建的数据保存或者覆盖到对应的序列上
      if (this.curIndex !== null) {
        data.splice(this.curIndex, 1, values)
      } else {
        data.push(values)
      }
      //提交到父级
      this.$emit("formSubmit", null, { work_experiences: data })
    }

  },
  computed: {
    workTypeOptions: () => ["请选择", "全职", "兼职", "实习"],

  },
  components: {
    FormGenerator
  },
  watch: {},

}
</script>
<style lang="scss">
</style>