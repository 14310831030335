<template>
  <div>
    <div class="page-user page-container" v-if="resume && isToken">

      <div class="page-user-wapper">
        <div class="resume-warper left">
          <div class="resume-warper-head">
            <TextHeader style="padding-left:28px" title="我的简历" />
            <div class="resume-refresh">
              <el-popconfirm confirm-button-text='好的' cancel-button-text='不用了' icon="el-icon-info" icon-color="ff9967"
                title="简历完成度 >=70 才能刷新成功哦！" @confirm="resumeRefresh">
                <div slot="reference"> 刷新简历 <i class="el-icon-refresh"></i></div>
              </el-popconfirm>
              <div class="resume-complete">
                简历完成度：{{ resume.resume_complete }}%
              </div>
              <div class="prompt-text">完善所有简历信息更容易被企业看中哦</div>
            </div>
          </div>



          <Basic ref="basic-component" :data="resume.basicData" @formSubmit="formSubmit" @avatarChange="beforeUpload" />

          <Intention ref="intention-component" :data="resume.intentionData" @formSubmit="formSubmit" />

          <WorkExperiences ref="work-experiences-component" :data="resume.work_experiences" @formSubmit="formSubmit" />

          <Educations ref="educations-component" :data="resume.educations" @formSubmit="formSubmit" />

          <Projects ref="projects-component" :data="resume.projects" @formSubmit="formSubmit" />

          <Prompt ref="prompt-component" :data="resume.prompt" @formSubmit="formSubmit" />

          <div class="update-time">
            更新时间：{{
              dealWithTime(resume.updated_at, "YYYY年MM月DD日 hh:mm")
            }}
          </div>
        </div>

        <div class="user-operation-record right">
          <!-- <el-tabs class="tabs" v-model="activeName" stretch @tab-click="handleTabClick"> -->
          <el-tabs class="tabs" v-model="activeName" @tab-click="handleTabClick">
            <el-tab-pane lazy label="我的投递" name="deliver">
              <DeliverRecord />
            </el-tab-pane>
            <!-- <el-tab-pane lazy label="我的报名" name="apply">
              <ApplyRecord/>
            </el-tab-pane> -->
          </el-tabs>
        </div>
      </div>

      <el-dialog width="400px;" title="提示" :visible.sync="showTransferResumeQr" :close-on-click-modal="false">
        <div >
          <p style="margin-top: -16px;margin-bottom: 24px;font-size: 16px;text-indent: 2em;line-height: 1.8;">因衢州人才网所有权人变更，原「衢州人才网官网」小程序将停止服务，请扫码绑定新的小程序并填写信息同步原「衢州人才网官网」小程序的简历。</p>
          <div style="text-align:center">
            <el-image lazy style="width: 260px; height: 260px" :src="require('assets/tbjl_qr.png')" fit="container">
            <div class="img-error" slot="error">{{ imgErrTitle }}</div>
          </el-image>
          </div>
         
        </div>
      </el-dialog>
    </div>

    <div v-else class="page-user page-container">
      <div class="nobody">
        <el-image lazy style="width: 200px; height: 200px" :src="miniprogram_qr_code_url" fit="cover">
          <div class="img-error" slot="error">{{ imgErrTitle }}</div>
        </el-image>
        <div class="ts">请使用微信扫码绑定衢州人才网小程序同步简历</div>
        <div class="ts">为保证使用体验，推荐使用谷歌浏览器、火狐浏览器以及360极速浏览器</div>
        <div class="ts">如二维码加载失败请更换浏览器或跟换网络环境</div>
        <div class="ts">如有操作疑问，请联系首页在线客服进行咨询</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import TextHeader from "components/textHeader";
// import WxScanQr from "components/wxScanQr"

//左侧 简历
import Basic from "components/resume/basic";
import Intention from "components/resume/intention";
import Educations from "components/resume/education";
import WorkExperiences from "components/resume/workExperiences";
import Projects from "components/resume/projects";
import Prompt from "components/resume/prompt"
//右侧 记录
import DeliverRecord from "components/resume/record/deliver"

// import ApplyRecord from "components/resume/record/apply"

export default {
  name: "PageUser",
  data: () => ({
    activeName: "deliver",
    resume: null,
    miniprogram_qr_code_url: "",
    qrlxTimer: null,
    imgErrTitle: '',
    showTransferResumeQr: false,
  }),

  computed: {
    ...mapState({
      user_id: (state) => state.user_id,
      resume_id: (state) => state.userInfo.resume_id,
      official_open_id: (state) => state.official_open_id,
      isToken: (state) => state.token ? true : false,
    }),
  },
  created() {
    this.getWxQr(this.official_open_id);
    this.imgErrTitle = "请等待。。。"
    if (this.user_id && this.resume_id) {
      this.getUserResume();
    }
    if (this.official_open_id && !this.isToken) {
      if (this.official_open_id) {
        this.getWxQr(this.official_open_id);
        this.defaultlxNmus(this.getLx);
      } else if (!this.official_open_id && !this.isToken) {
        this.$message({
          message: "请重新登陆后刷新页面",
          type: 'warning'
        })
        this.$nextTick(() => {
          this.imgErrTitle = "获取二维码失败"
        })
      }
    }
  },
  methods: {
    ...mapMutations(["setToken", "setUserId", "setOpenId"]),
    ...mapActions(["getUserInfo"]),

    handleTabClick() {

    },
    beforeUpload(event) {
      let files = event.target.files;
      let reader = new FileReader();
      if (files) {
        reader.onload = (e) => {
          let result = e.target.result;
          let imageData = {
            image: result
              .replace("data:image/jpeg;base64,", "")
              .replace("data:image/jpg;base64,", "")
              .replace("data:image/png;base64,", ""),
            image_name: "image.jpg",
          };
          this.onUploadFile(imageData);
        };
        if (files.length > 0) reader.readAsDataURL(files[0]);
      }
    },
    onUploadFile(data) {
      this.$axiosRequest({
        method: "POST",
        name: "postAvatarImage",
        ids: [this.user_id],
        token: true,
        data,
      })
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            console.log("upload", res.data);
            this.$notify({
              title: "成功",
              message: "上传成功",
              type: "success",
              duration: 1500,
            });
            this.getUserResume();
          }
        })
        .catch((err) => {
          this.$notify.error({
            title: "失败",
            message: "上传失败，请重试",
            duration: 1500,
          });
          console.log(err);
        });
    },
    //提交表单
    formSubmit(valid, values) {
      console.log('formSubmit', valid, values);
      let data = { ...values };

      if (data.expect_industry) {
        data.expect_positions = data.expect_industry[2];
        data.expect_industry =
          data.expect_industry[0] + "-" + data.expect_industry[1];
      }

      this.setResumeData(data);
    },
    //保存简历
    setResumeData(data) {
      this.$axiosRequest({
        method: "PUT",
        name: "putResumeData",
        ids: [this.user_id, this.resume_id],
        token: true,
        data,
      })
        .then((res) => {
          if (res.status === 200) {
            console.log("setResume", res.data);
            this.$notify({
              title: "成功",
              message: "保存成功",
              type: "success",
              duration: 1500,
            });
            this.getUserResume(false);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$notify.error({
            title: "失败",
            message: "保存失败，请重试",
            duration: 1500,
          });
        });
    },
    //时间格式化处理函数
    dealWithTime(time, key) {
      return this.$dayjs(time).format(key ? key : "YYYY/MM") || null;
    },
    //获取用户简历
    getUserResume(showQrCode = true) {
      this.$axiosRequest({
        name: "getWxResume",
        ids: [this.user_id, this.resume_id],
        token: true,
      })
        .then((res) => {
          if (res.status === 200) {
            // console.log("resume",res.data);
            let data = { ...res.data };
            this.resume = {
              person_avatar: data.person_avatar,
              resume_complete: data.resume_complete,
              basicData: {
                person_avatar: data.person_avatar,
                name: data.name,
                sex: data.sex,
                birth: data.birth,
                id_card: data.id_card,
                phone: data.phone,
                email: data.email,
                degree: data.degree,
                living_address: data.living_address,
                ji_guan: data.ji_guan,
                worked_years: data.worked_years,
                job_hunting_status: data.job_hunting_status,
                expect_positions: data.expect_positions,

              },
              intentionData: {
                expect_industry: data.expect_industry,
                expect_positions: data.expect_positions,
                expect_areas: data.expect_areas,
                expect_salary: data.expect_salary,
              },
              educations: data.educations,
              projects: data.projects,
              work_experiences: data.work_experiences,
              prompt: {
                summary: data.summary
              }
            };
            // data.expect_industry = [...res.data.expect_industry.split("-"),res.data.expect_positions]
             this.showTransferResumeQr = showQrCode;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取微信小程序二维码
    getWxQr(official_open_id) {
      let scene = `${official_open_id}~${this.user_id ? 1 : 0}`;
      this.$axiosRequest({
        name: "getWxLoginQr",
        params: { scene: scene, page: "pages/resume/resume", source_web: 5 },
      })
        .then((res) => {
          if (res.status === 200) {
            this.miniprogram_qr_code_url = res.data.miniprogram_qr_code_url;
            console.log("qr", res.data);
          } else {
            this.imgErrTitle = "获取失败"
          }

        })
        .catch((err) => {
          console.log(err);
        });
    },
    //开启轮训的初始化函数
    defaultlxNmus(fn) {
      this.lxnums = 150;
      this.outQr = false;
      if (fn) {
        fn();
      }
    },
    //轮询函数
    getLx() {
      this.$axiosRequest({
        name: "lx",
        params: {
          qr_code_url_id: JSON.parse(localStorage.getItem("PublicQrData"))
            .qr_code_url_id,
        },
      })
        .then((res) => {
          this.lxnums -= 1;
          if (res.status === 200) {
            if (
              res.data.focus_status !== false &&
              res.data.token &&
              res.data.user_id
            ) {
              this.setToken(res.data.token);
              this.setUserId(res.data.user_id);
            } else {
              this.qrlxTimer = setTimeout(() => {
                if (this.lxnums > 0) this.getLx();
                else this.outQr = true;
              }, 2000);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //刷新简历
    resumeRefresh() {
      if (this.resume.resume_complete < 70) {
        this.$alert('您的简历完成度未到 70%，请完善您的简历 ', '刷新失败', {
          confirmButtonText: '我知道了',
        });
        return
      }
      console.log("启动刷新");
      this.$axiosRequest({
        method: "PUT",
        name: "putRefresh",
        ids: [this.user_id, this.resume_id],
        token: true,
      }).then(res => {
        console.log(res);

        this.$message({
          message: "刷新成功，您的简历已被置顶，可获得更多求职机会",
          type: "success"
        })
      }).catch(err => {
        console.log(err);
      })

      // this.$axiosRequest({
      //   method:"",
      //   name: "",
      // })
    }
  },
  watch: {
    resume_id(now, prev) {
      if (now !== prev && now) {
        this.getUserResume();
      }
    },
  },
  components: {
    Basic,
    Intention,
    TextHeader,
    Educations,
    WorkExperiences,
    Projects,
    Prompt,

    DeliverRecord,
    // ApplyRecord
  },
};
</script>
<style lang="scss">
@import "constants/style/public";

.page-user {
  text-align: left;
  margin-bottom: 90px;

  .prompt-text {
    text-align: right;
    font-size: var(--little, 12px);
    color: var(--promptColor, #999)
  }

  .resume-complete {
    margin-top: 6px;
    font-size: var(--medium, 14px);
    color: #666;
  }

  .page-user-wapper {
    display: flex;
    justify-content: space-between;

    .resume-warper {
      .resume-warper-head {
        padding-right: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .resume-refresh {
          margin-top: 20px;
          text-align: right;
          color: var(--cardsubTextColor, #666);
          cursor: pointer;
          transition: color 0.2s;

          i {
            transform: scale(1.2);
            transform-origin: center;
          }

          &:hover {
            color: var(--primaryColor, #ff9967)
          }

        }
      }
    }

    .left {
      flex: 1;
      background-color: #fff;
      border-left: 1px solid $lineColor;
      border-right: 1px solid $lineColor;
      border-bottom: 1px solid $lineColor;
    }

    .right {
      width: 26%;
      padding: 16px 28px 26px;
      height: 1000px;
      // border-left: 1px solid $lineColor;
      background-color: #fff;
    }
  }

  .tabs {
    width: 100%;

    .el-tabs__active-bar {
      background-color: $moreColor;
    }

    .el-tabs__item {

      &.is-active,
      &:hover {
        color: $moreColor
      }
    }

    .record-wapper {
      padding-top: 12px;
    }
  }

  //basic 部分
  .resume-basic-warper {
    padding: 26px 28px 26px;
    display: flex;

    .avator-image {
      border: 1px solid transparent;
      border-radius: 8px;
      cursor: pointer;

      &:hover {
        border: 1px solid #efefef;
      }

      &:active {
        transform: scale(0.95);
        transform-origin: center;
      }
    }

    .info-message {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-left: 50px;

      .info-message-left {
        display: flex;
        flex-direction: column;

        .info-message-left-item {
          align-items: center;
          display: flex;
          margin-bottom: 28px;
          font-size: 14px;

          .name {
            color: $titleColor;
            font-size: $extraLarge;
          }

          .expect {
            margin-left: 20px;
            align-self: flex-end;
            border: 1px solid $moreColor;
            color: $moreColor;
            padding: 1px 10px;
            border-radius: 3px;
          }

          .job-hunting,
          .resume-complete {
            margin-left: 20px;
            align-self: flex-end;
          }

          .phone,
          .email {
            margin-right: 40px;

            >span {
              color: $promptColor;
            }
          }
        }
      }
    }
  }

  .job-intention,
  .works,
  .edu,
  .projects {
    padding: 26px 28px;
    background-color: #fff;
    font-size: 18px;
    color: $titleColor;

    .job-intention-content-name {
      display: flex;
      margin-bottom: 12px;

      .name {
        font-weight: 400;
        margin-right: 30px;
      }

      .selary {
        vertical-align: center;
        color: $moreColor;
      }
    }

    .job-intention-content-bottom {
      font-size: $medium;
      display: flex;

      .adress,
      .industry {
        margin-right: 40px;
      }
    }

    .item-box {
      margin-bottom: 20px;
      font-size: 14px;
    }

    .subspan {
      margin-right: 10px;
      color: $promptColor;
    }

    .company-name,
    .school-name {
      font-size: 18px;
      margin-bottom: 12px;
    }

    .tag {
      margin-left: 10px;
      display: inline-block;
      padding: 1px 10px;
      border: 1px solid $moreColor;
      border-radius: 3px;
      font-size: 12px;
      color: $moreColor;
    }

    .work-info,
    .edu-info {
      display: flex;
      margin-bottom: 12px;

      .work-name,
      .edu-name {
        margin-right: 30px;
      }
    }

    .work-content {
      .summary {
        text-indent: 28px;
      }
    }
  }

  .info-message-right {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .btn {
      background: $linear;
      color: #fff;
      border: 0;
    }

    .prompt {
      margin-top: 30px;
      font-size: 12px;
      color: $promptColor;
    }
  }

  .add-content {
    text-decoration: none;
    display: flex;
    align-items: center;
    color: $moreColor;
    cursor: pointer;
  }

  .Line {
    width: 1px;
    height: 18px;
    display: inline-block;
    background-color: #ccc;
    margin: 0 12px;
  }

  .titler-header-box {
    display: flex;
    justify-content: space-between;
  }

  .item-content-wraper {
    display: flex;
    font-size: 16px;
    justify-content: space-between;
  }

  .cont-title {
    font-size: 18px;
    color: $titleColor;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .update-time {
    background-color: #fff;
    padding: 20px 20px 40px;
    text-align: center;
    font-size: $little;
    color: $promptColor;
  }

  .nobody {
    text-align: center;
    padding: 50px;
    background-color: #fff;
  }

  .ts {
    margin-top: 15px;

    &:first-of-type {
      margin-top: 25px;
    }
  }

  .delete {
    margin-right: 40px;
    color: $danger;
  }

  .operation-btn-box {
    display: flex;
  }

  .resume-prompt-warper {
    padding: 26px 28px 26px;
    display: flex;
    justify-content: space-between;
  }

  .img-error {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 1px solid #eee;
    display: flex;
    justify-content: center;
    background-color: #fcfcfc;
    align-items: center;
    color: #999;
    font-size: 12px;
  }

}
</style>