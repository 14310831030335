<template>
  <div class="projects">
    <div class="titler-header-box">
      <div class="cont-title">项目经历</div>
      <div class="info-message-right">
        <a @click="created" class="add-content">
          <i class="el-icon-edit icon"></i>
          <div style="margin-left:10px">新建</div>
        </a>
      </div>
    </div>

    <!-- 项目经历的Modal框 -->
    <el-dialog title="项目经历" :visible.sync="visibility"  :close-on-click-modal="false">
      <FormGenerator v-if="visibility" ref="forms" :defaultValue="projectData" :formOption="projectModalOptions"
        @close="changMotal()" @submit="submit" />
    </el-dialog>
    <!-- ****** -->

    <div v-for="item, index in data" :key="index" class="item-box">

      <div class="item-content-wraper">
        <div class="company-name">{{ item.project_name || '项目名称' }}
        </div>
        <div style="display:felx;" class="operation-btn-box">
          <span @click="deleteData(index)" class="add-content delete">
            <i class="el-icon-circle-close icon"></i>
            <div style="margin-left:10px">删除</div>
          </span>
          <a @click="processData(index)" class="add-content ">
            <i class="el-icon-edit icon"></i>
            <div style="margin-left:10px">编辑</div>
          </a>
        </div>
      </div>

      <div class="company-name"></div>
      <div class="work-info">
        <div class="work-time"><span
            class="subspan">项目时间：</span>{{ dealWithTime(item.start_date) + '-' + dealWithTime(item.end_date) }}</div>
      </div>
      <div class="work-content">
        <div class="subspan">项目描述:</div>
        <div class="summary">{{ item.summary }}</div>
      </div>
    </div>
  </div>

</template>
<script>
import FormGenerator from 'components/formGenerator/formGenerator'
// import {resumeData} from "constants/publicData"
export default {
  name: "Projects",
  props: ["data"],
  data: () => ({
    visibility: false,
    projectData: {},
    curIndex: null,
    projectModalOptions: {
      labelPosition: 'top',
      formClass: 'lable-position-top',
      formName: 'projectsFrom',
      items: [
        {
          type: 'input',
          label: '项目名称',
          key: 'project_name',
          placeholder: '请输入项目名称',
          rules: [{ required: true, message: "请输入项目名称" }]
        },
        {
          type: 'select-monthrange',
          label: '项目时间',
          key: 'doubleTime',
          startPlaceholder: '请选择立项时间',
          endPlaceholder: '请选择结项时间',
          rules: [{ required: true, message: "请选择立项时间以及结项时间" }]
        },
        {
          type: 'textarea',
          label: '项目描述',
          key: 'summary',
          rules: [{ required: true, message: "请输入项目描述" }]
        },
      ]
    }
  }),
  methods: {
    created() {
      this.curIndex = null
      this.projectData = {}
      this.changMotal()
    },
    dealWithTime(time, key) {
      return this.$dayjs(time).format(key ? key : "YYYY/MM") || null
    },
    changMotal() {
      this.visibility = !this.visibility
    },
    processData(index) {
      this.curIndex = index
      let data = { ...this.data[index] }
      data.doubleTime = [new Date(data.start_date), new Date(data.end_date)]
      this.projectData = data
      this.changMotal()
    },

    deleteData(index) {
      let data = [...this.data]
      data.splice(index, 1)
      this.$emit("formSubmit", null, { projects: data })
    },
    submit(valid, value) {
      console.log(valid, value);
      let data = [...this.data]
      value.start_date = value.doubleTime[0]
      value.end_date = value.doubleTime[1]
      delete value.doubleTime
      if (this.curIndex !== null) {
        data.splice(this.curIndex, 1, value)
      } else {
        data.push(value)
      }
      this.$emit("formSubmit", null, { projects: data })
    }

  },
  computed: {

  },
  components: {
    FormGenerator
  },
  watch: {},

}
</script>
<style lang="scss">
</style>