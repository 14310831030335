<template>
  <div class="resume-basic-warper" v-if="data">
    <el-image
      @click="uploadAvatar"
      class="avator-image"
      style="width: 120px; height: 140px"
      :src="data.person_avatar"
      fit="cover"
    />

    <div class="info-message">
      <div class="info-message-left">
        <div class="info-message-left-item">
          <div class="name">{{ data.name || '姓名' }}</div>
          <div class="expect">
            {{ data.expect_positions }}
          </div>
          <div class="job-hunting">
            {{ jobHuntingStatusTextMap[data.job_hunting_status - 1] }}
          </div>
        </div>
        <div class="info-message-left-item">
          <span>{{ data.sex }}</span>
          <el-divider direction="vertical" />
          <span>{{ computedAge }}岁</span>
          <el-divider direction="vertical" />
          <span>{{ data.degree }}</span>
          <el-divider direction="vertical" />
          <span>{{ data.worked_years }}年</span>
        </div>
        <div class="info-message-left-item">
          <div class="phone"><span>电话：</span>{{ data.phone }}</div>
          <div class="email"><span>邮箱：</span>{{ data.email }}</div>
        </div>
      </div>

      <div class="info-message-right">
        <a @click="changMotal" class="add-content">
          <i class="el-icon-edit icon"></i>
          <div style="margin-left: 10px">编辑</div>
        </a>
      </div>
    </div>
    <!-- 基本信息的Modal框 -->
    <el-dialog
      title="基本信息"
      v-if="data"
      :visible.sync="visibility"
      :close-on-click-modal="false"
    >
      <FormGenerator
        ref="forms"
        v-if="visibility"
        :defaultValue="processBasicData(data)"
        :formOption="basicResumeOptions"
        @close="changMotal"
        @submit="submit"
      />
    </el-dialog>
    <input type="file" style="display: none" id="avatar" ref="avatar" @change="beforeUpload" />
  </div>
</template>
<script>
import FormGenerator from 'components/formGenerator/formGenerator';
import { resumeData, /*category, city,*/ wxCity } from 'constants/publicData';
export default {
  name: 'Basic',
  props: ['data'],
  data: () => ({
    visibility: false,
    curIndex: null,
    basicResumeOptions: {
      labelPosition: 'top',
      formClass: 'lable-position-top',
      formName: 'basicForm',
      items: [
        {
          type: 'input',
          label: '姓名',
          key: 'name',
          placeholder: '请输入姓名',
          rules: [{ required: true, message: '请输入姓名' }],
        },
        {
          type: 'select',
          label: '性别',
          key: 'sex',
          placeholder: '请选择性别',
          options: ['男', '女'],
          rules: [{ required: true, message: '请选择性别' }],
        },
        {
          type: 'select-year',
          label: '出生日期',
          key: 'birth',
          placeholder: '请选择出生日期',
          format: 'yyyy',
          rules: [{ required: true, message: '请选择出生日期' }],
        },
        {
          type: 'idCard',
          label: '身份证号(或外国人永久居留身份证)',
          key: 'id_card',
          placeholder: '请输入身份证号',
          rules: [{ required: true, message: '请输入身份证号' }],
        },
        {
          type: 'phone',
          label: '手机号',
          key: 'phone',
          placeholder: '请输入手机号',
          rules: [{ required: true, message: '请输入手机号' }],
        },
        {
          type: 'email',
          label: '邮箱',
          key: 'email',
          placeholder: '请输入邮箱',
          rules: [{ required: true, message: '请输入邮箱' }],
        },
        {
          type: 'select',
          label: '学历',
          key: 'degree',
          placeholder: '请选择学历',
          options: resumeData.degrees,
          rules: [{ required: true, message: '请选择学历' }],
        },
        {
          type: 'search-select',
          label: '居住地',
          key: 'living_address',
          placeholder: '请选择居住地',
          options: wxCity,
          rules: [{ required: true, message: '请选择居住地' }],
        },
        {
          type: 'search-select',
          label: '籍贯',
          key: 'ji_guan',
          placeholder: '请选择籍贯',
          options: wxCity,
          rules: [{ required: true, message: '请选择籍贯' }],
        },
        {
          type: 'inputNumber',
          label: '工作经验（年）',
          key: 'worked_years',
          min: 0,
          placeholder: '请输入工作经验的年数',
          rules: [{ required: true, message: '请输入工作经验的年数' }],
        },
        {
          type: 'select',
          label: '求职状态',
          key: 'job_hunting_status',
          placeholder: '请选择求职状态',
          options: resumeData.jobHuntingStatusOptions,
          rules: [{ required: true, message: '请选择求职状态' }],
        },
      ],
    },
  }),
  methods: {
    created() {
      this.curIndex = null;
      this.eduData = {};
      this.changMotal();
    },
    processBasicData(data) {
      const { worked_years } = data;
      let newData = {
        ...data,
        worked_years: String(worked_years || worked_years === 0 ? worked_years : ''),
      };
      return newData;
    },
    uploadAvatar() {
      document.querySelector('#avatar').click();
    },
    beforeUpload(e) {
      this.$emit('avatarChange', e);
    },
    dealWithTime(time, key) {
      return this.$dayjs(time).format(key ? key : 'YYYY/MM') || null;
    },
    changMotal() {
      this.visibility = !this.visibility;
    },
    submit(valid, value) {
      console.log(value);
      this.$emit('formSubmit', valid, value);
    },
  },
  computed: {
    jobHuntingStatusTextMap: () => resumeData.jobHuntingStatusOptions.map(o => o.label),
    computedAge() {
      if (this.data && this.data.birth) {
        return this.$dayjs().year() - this.data.birth;
      }
      return null;
    },
  },
  components: {
    FormGenerator,
  },
  watch: {},
};
</script>
