<template>
  <div class="resume-prompt-warper" v-if="data">
    <div>
      <div class="cont-title">个人简介</div>
      <div>{{ data.summary }}</div>
    </div>


    <div class="info-message-right">
      <a @click="changMotal" class="add-content">
        <i class="el-icon-edit icon"></i>
        <div style="margin-left: 10px">编辑</div>
      </a>
    </div>

    <!-- 基本信息的Modal框 -->
    <el-dialog title="自我介绍" v-if="data" :visible.sync="visibility" :close-on-click-modal="false">
      <FormGenerator v-if="visibility" ref="forms" :defaultValue="processBasicData(data)"
        :formOption="basicResumeOptions" @close="changMotal" @submit="submit" />
    </el-dialog>
  </div>
</template>
<script>
import FormGenerator from 'components/formGenerator/formGenerator'
export default {
  name: "Prompt",
  props: ["data"],
  data: () => ({
    visibility: false,
    curIndex: null,
    basicResumeOptions: {
      labelPosition: "top",
      formClass: "lable-position-top",
      formName: "basicForm",
      items: [
        {
          type: "textarea",
          label: "个人简介",
          key: "summary",
          rows: 8,
          placeholder: "请输入个人简介",
          rules: [{ required: true, message: "请输入个人简介" }],
        },
      ],
    },
  }),
  methods: {
    created() {
      this.curIndex = null
      this.eduData = {}
      this.changMotal()
    },
    processBasicData(data) {
      let newData = { ...data };
      return newData;
    },
    uploadAvatar() {
      document.querySelector("#avatar").click();
    },
    beforeUpload(e) {
      this.$emit('avatarChange', e)
    },
    dealWithTime(time, key) {
      return this.$dayjs(time).format(key ? key : "YYYY/MM") || null
    },
    changMotal() {
      this.visibility = !this.visibility
    },
    submit(valid, value) {
      this.$emit("formSubmit", valid, value)
    }

  },
  computed: {
  },
  components: {
    FormGenerator
  },
  watch: {},

}
</script>
