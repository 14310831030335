<template>
  <div :class="item.class" :style="item.style">

    <FormGeneratorItem v-if="contextType===0" v-model="state" :item="item" />

    <!-- 子项单数 -->
    <div class="form-item-container" v-if="contextType===1">
      <div class="form-item-container-title" :class="item.isRequired&&'is-required'">
        {{item.label}}
      </div>
      <div class="form-item-container-box">
        <div 
          v-for="o in item.children" 
          :key="o.key" :style="o.style" 
          :class="item.lablePosition==='left'?'label-inline':''">
          <div class="item-label el-form-item__label" >
            {{o.label}}
          </div>
          <FormGeneratorItem
            v-model="state[o.key]"
            :item="o"
            :itemKey="item.key+'.'+ o.key" 
            :noDefaultLabel="item.noDefaultLabel||o.noDefaultLabel"
          />
        </div>
      </div>
    </div>

    <!-- 子项复数并可通过按钮生成新的 -->
    <div class="form-item-container" v-if="contextType===2">
      <div class="form-item-container-title" :class="item.isRequired&&'is-required'">
        {{item.label}}
      </div>
      <div v-for="n,i in leng" :key="n" class="form-item-container-box">
        <div 
          v-for="o in item.children" 
          :key="o.key" :style="o.style" 
          :class="item.lablePosition==='left'?'label-inline':''">
          <div class="item-label el-form-item__label" >
            {{o.label}}
          </div>
          <!-- itemKey -->
          <FormGeneratorItem
            :value="arr[i]? arr[i][o.key]:null"
            :item="o"
            :itemKey="item.key+'['+i+'].'+ o.key" 
            @change="pluralForm(i,o.key,$event)"
            :noDefaultLabel="item.noDefaultLabel||o.noDefaultLabel"
          />
        </div>
      </div>
      <div class="add-button" v-if="item.plural">
        <el-button type="primary" size="mini" @click="add">添加</el-button>
        <el-popconfirm
          style="margin-left:15px"
          title="确定移除最后一个？"
          @confirm="remove"
        >
          <el-button slot="reference" type="danger" :disabled="this.leng<=1" size="mini" >移除最后一个</el-button>
        </el-popconfirm>
      </div>
    </div>

    
  </div>
</template>
<script>
import FormGeneratorItem from 'components/formGenerator/formGeneratorItem'
export default {
  name:"formGaneratorContainer",
  model:{
    props:"value",//props的默认值
    event:"change"//触发的事件
  },
  props:['item','value','itemIndex'],
  data:()=>({
    state:null,
    arr:[],
    leng:1
  }),
  methods:{
    add(){
      this.arr.push({})
      this.leng += 1
    },
    remove(){
      if(this.leng>1){
        this.arr.pop()
        this.leng -= 1
      }
      
    },
    pluralForm(index,key,value){
      let arr = [...this.arr]
      if(!arr[index]){
        arr.push({[key]:value})
      }
      arr[index][key] = value
      this.$emit("change",arr)
    }
  },
  computed:{
    contextType(){
      if(this.item.plural && this.item.children){
        return 2
      }else if(this.item.children){
        return 1
      }else{
        return 0
      }
    }
  },
  watch:{
    value:{
      handler(val){
        if(this.item.plural){
          if(val instanceof Array){
            this.leng = val.length
            this.arr = val
          }
          return
        }
        if(val) this.state = val
        else if(this.item.children) this.state = {}
      },
      immediate: true
    },
    state(val){
      this.$emit('change',val)
    },
  },
  components:{
    FormGeneratorItem
  },
}
</script>
<style lang="scss">
  .form-item-container{
    font-size: var(--medium,14px);
    margin: 15px 0 10px;
  }
  .form-item-container-box{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .label-inline{
    display: flex;
    .item-label{
      flex: none;
      margin-right: 10px;
    }
  }
  .form-item-container-title{
    padding:0 0 10px;
    &.is-required::before{
      content: '*';
      color: #F56C6C;
      margin-right: 4px;
    }
    &.request{
      
    }
  }
  .add-button{
    margin-bottom: 20px;
  }
// @import "constants/style/public";
</style>